import './style.scss';

function Footer () {
    

    return(
        <footer className="footer">
            <div className="container">
            <div className="footer-content">
                <div className="footer-block" >
                    <h2 className="headline">
                        Info
                    </h2>
                    <ul className="information-links">
                        <li>
                            <a href="/">
                                HOME
                            </a>
                        </li>
                        <li>
                            <a href="/about-us">
                                ÜBER UNS
                            </a>
                        </li>
                        <li>
                            <a href="/services">
                                DIENSTLEISTUNGEN
                            </a>
                        </li>
                        <li>
                            <a href="/contact">
                                KONTAKT
                            </a> 
                        </li>
                    </ul>
                <ul className="social-media-links">
                    <li>
                        <a href="https://www.facebook.com/geregurmobilautopflege">
                            <img src="img/fbicon.png" className='social-icon' alt='facebooklogo'/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/magictech_autopflege/">
                            <img src="img/instaicon.png" className='social-icon' alt='instalogo'/>
                        </a>
                    </li>
                    <li>
                        <a href="https://wa.me/436645337410">
                            <img src="img/whatsapplogo.png" className='social-icon' alt='whatsapplogo'/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.tiktok.com/@magictech.autopfl">
                            <img src="img/tiktoklogo.png" className='social-icon' alt='tiktoklogo'/>
                        </a>
                    </li>
                </ul>
                </div>
                <div className="logo">
                    <img src="/img/mtlogo2.webp" alt='magic tech logo' className="footer-logo"/>
                </div>
                <div className="footer-block">
                    <h2 className="headline">
                        Kontaktdaten
                    </h2>
                    <p className="footer-contact-information">
                        <span>Adresse:</span> 2630 Ternitz, Handelsstraße 4.
                    </p>
                    <div className="footer-contact-information open">
                        <span>Öffnungszeiten:</span>  
                        <div className='open'>
                            <p style={{margin: 0}}>Mo - Fr: 9:00 - 17:00</p>
                            <p>Sa: nach Vereinbarung</p>
                            <p>So: geschlossen</p>
                        </div>
                    </div>
                    <p className="footer-contact-information">
                        <span>Mobil:</span> <a href="tel:+436645337410" > +436645337410 </a>
                    </p>
                    <p className="footer-contact-information">
                        <span>E-mail:</span> <a href="mailto:Magictech1986at@gmail.com"> Magictech1986at@gmail.com </a>
                    </p>
                </div>
            </div>
    </div>
</footer>
    )
}
export default Footer;