import { Link } from 'react-router-dom';
import './style.css';
import { useEffect, useState } from 'react';


function Cover ({isOpened, setIsOpened}) {

    // transparent

    const [responsive, setResponsive] = useState(window.innerWidth > 1024);

    useEffect(() => {
        const handleResize = () => {
            setResponsive(window.innerWidth > 1024);
        };
        
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
        }, []);


    const scrollToFlipSide = () => {
        document.getElementById("").scrollIntoView({behavior:'smooth'});
    }

    return(
        <section id='cover' className='cover-component' style={{backgroundImage:'url(/img/mtcover3.jpg)'}} >
            <div className='container'>
                <div className='textbox-centered' style={{ backgroundColor: responsive ? '' : 'transparent' }}>
                    {responsive ? (
                        <div className='desktop-text'>
                            <h1 className='cover-component-title'>
                                Herzlich Willkommen bei <span>Magic-Tech!</span>
                            </h1>
                            <h2 className='cover-component-headline'>
                                Handwerkliche Präzision, zeitlose Schönheit!
                            </h2>
                        </div>
                                ) : (
                                    <div className='desktop-text'>
                                    <h1 className='cover-component-title'>
                                        Herzlich Willkommen bei <span>Magic Tech!</span>
                                    </h1>
                                </div>
                    )}
                    <Link to={"/contact"}>
                        <button className='cover-component-button'>
                            Zur Angebot!
                        </button>
                    </Link>
                    
                </div>
                <div className='service-buttons'>
                    <Link to={"/services"}>
                        <button className='service-button'>
                            Versiegelung
                        </button>
                    </Link>
                    <Link to={"/services"}>
                        <button className='service-button'>
                            Politur
                        </button>
                    </Link>
                    <Link to={"/services"}>
                        <button className='service-button'>
                            Innenreinigung 
                        </button>
                    </Link>                                                      
                </div>
            </div>
        </section>
    );
};

export default Cover;